import React from 'react';
import {Flex} from 'antd';
import HeatMapComponent from "./HeatMapComponent";

const HeatMapsTabComponent = ({data, extractor}) => {
    return (
        <Flex gap="small" wrap="wrap">
            {Object.entries(data).map(([key, value]) =>
                (<HeatMapComponent
                    name={key}
                    data={value}
                    extractor={extractor}
                />)
            )}
        </Flex>
    );
}

export default HeatMapsTabComponent;