import './App.css';
import ConfigurationForm from './ConfigurationForm';
import React, {useState} from "react";
import ResultForm from "./ResultForm";

function App() {
    const [result, setResult] = useState({});

    return (
        <div className="App">
            <header className="App-header">
                <a className="App-link" href="https://uniconyx.com">
                    <h1>Uniconyx</h1>
                </a>
            </header>
            <ConfigurationForm onSubmit={(responseData) => {
                setResult(responseData)
            }}/>
            <ResultForm responseData={result}/>
        </div>
    );
}

export default App;
