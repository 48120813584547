import React, {useState} from 'react';
import {Button, Card, Flex, InputNumber, Typography, message} from 'antd';
import axios from 'axios';

const ConfigurationForm = ({onSubmit}) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        A: {x: 0, y: 0, z: 9000},
        B: {x: 10000, y: 0, z: 9000},
        C: {x: 10000, y: 10000, z: 9000},
        D: {x: 0, y: 10000, z: 9000},
        thWeight: 50,
        thRadius: 200,
        thHeight: 700,
        velocity: 300,
        cableDiameter: 6,
        printableHeight: 6000,
        stepXY: 100,
        stepZ: 200,
        stepperTorque: 12,
        stepperRpm: 300,
        stepperReduction: 7,
    });

    const handleOnClick = (event) => {
        event.preventDefault();
        setLoading(true)
        axios.post('http://localhost:8080/simulate', {
            anchorA: {x: formData.A.x, y: formData.A.y, z: formData.A.z},
            anchorB: {x: formData.B.x, y: formData.B.y, z: formData.B.z},
            anchorC: {x: formData.C.x, y: formData.C.y, z: formData.C.z},
            anchorD: {x: formData.D.x, y: formData.D.y, z: formData.D.z},
            thWeight: formData.thWeight,
            thRadius: formData.thRadius,
            thHeight: formData.thHeight,
            velocity: formData.velocity,
            cableDiameter: formData.cableDiameter,
            printableHeight: formData.printableHeight,
            stepXY: formData.stepXY,
            stepZ: formData.stepZ,
            stepperTorque: formData.stepperTorque,
            stepperRpm: formData.stepperRpm,
            stepperReduction: formData.stepperReduction,
        })
            .then(response => {
                setLoading(false)
                onSubmit(response.data);
            })
            .catch(error => {
                message.error("Failed to fetch data: " + error)
                setLoading(false)
            });
    };

    return (
        <form>
            <h1>Workplace simulator</h1>
            <Flex gap="large" wrap="wrap">
                <Card title="Anchors">
                    {['A', 'B', 'C', 'D'].map(anchor => (
                        <div key={anchor}>
                            <Typography.Title level={5}>{anchor}</Typography.Title>
                            {['x', 'y', 'z'].map(axis => (
                                <InputNumber key={`${anchor}.${axis}`} min={0} max={100000}
                                             defaultValue={formData[anchor][axis]}
                                             onChange={value => setFormData({
                                                 ...formData,
                                                 [anchor]: {
                                                     ...formData[anchor],
                                                     [axis]: value
                                                 }
                                             })}/>
                            ))}
                        </div>
                    ))}
                </Card>

                <Card title="Toolhead">
                    <div>
                        <Typography.Title level={5}>Weight, kg</Typography.Title>
                        <InputNumber key={`thWeight`} min={0} max={1000} defaultValue={formData.thWeight}
                                     onChange={value => setFormData({...formData, thWeight: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Radius, mm</Typography.Title>
                        <InputNumber key={`thRadius`} min={0} max={1000} defaultValue={formData.thRadius}
                                     onChange={value => setFormData({...formData, thRadius: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Height, mm</Typography.Title>
                        <InputNumber key={`thHeight`} min={0} max={10000} defaultValue={formData.thHeight}
                                     onChange={value => setFormData({...formData, thHeight: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Max velocity, mm/s</Typography.Title>
                        <InputNumber key={`velocity`} min={0} max={1000} defaultValue={formData.velocity}
                                     onChange={value => setFormData({...formData, velocity: value})}/>
                    </div>
                </Card>

                <Card title="Winch">
                    <div>
                        <Typography.Title level={5}>Cable diameter, mm</Typography.Title>
                        <InputNumber key={`cableDiameter`} min={0} max={1000} defaultValue={formData.cableDiameter}
                                     onChange={value => setFormData({...formData, cableDiameter: value})}/>
                    </div>
                </Card>

                <Card title="Stepper">
                    <div>
                        <Typography.Title level={5}>Stepper torque, Nxm</Typography.Title>
                        <InputNumber key={`stepperTorque`} min={0} max={100} defaultValue={formData.stepperTorque}
                                     onChange={value => setFormData({...formData, stepperTorque: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Stepper RPM, rpm</Typography.Title>
                        <InputNumber key={`stepperRpm`} min={0} max={10000} defaultValue={formData.stepperRpm}
                                     onChange={value => setFormData({...formData, stepperRpm: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Stepper reduction</Typography.Title>
                        <InputNumber key={`stepperReduction`} min={0} max={100} defaultValue={formData.stepperReduction}
                                     onChange={value => setFormData({...formData, stepperReduction: value})}/>
                    </div>
                </Card>

                <Card title="Simulation">
                    <div>
                        <Typography.Title level={5}>Printable height, mm</Typography.Title>
                        <InputNumber key={`printableHeight`} min={0} max={100000} defaultValue={formData.printableHeight}
                                     onChange={value => setFormData({...formData, printableHeight: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Step XY, mm</Typography.Title>
                        <InputNumber key={`stepXY`} min={0} max={10000} defaultValue={formData.stepXY}
                                     onChange={value => setFormData({...formData, stepXY: value})}/>
                    </div>
                    <div>
                        <Typography.Title level={5}>Step Z, mm</Typography.Title>
                        <InputNumber key={`stepZ`} min={0} max={10000} defaultValue={formData.stepZ}
                                     onChange={value => setFormData({...formData, stepZ: value})}/>
                    </div>
                </Card>
            </Flex>
            <p/>
            <Button type="primary" loading={loading} onClick={handleOnClick}>Simulate</Button>
        </form>
    );
};

export default ConfigurationForm;