import React from 'react';
import {Card, Flex, Tabs, Typography} from 'antd';
import HeatMapsTabComponent from "./HeatMapsTabComponent";

const ResultForm = ({responseData}) => {
    return (
        <div>
            {Object.keys(responseData).length > 0 &&
                <div>
                    <p/>
                    <Card title="Summary">
                        <Flex gap="small" wrap="wrap">
                            <Card title="Workplace">
                                <Typography.Title level={5}>
                                    X: [{responseData.workplaceX.from}, {responseData.workplaceX.to}] mm
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Y: [{responseData.workplaceY.from}, {responseData.workplaceY.to}] mm
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Z: [{responseData.workplaceZ.from}, {responseData.workplaceZ.to}] mm
                                </Typography.Title>
                            </Card>
                            <Card title="Cable">
                                <Typography.Title level={5}>
                                    Length delta: {responseData.cableLength.to - responseData.cableLength.from} [{responseData.cableLength.from}, {responseData.cableLength.to}] mm
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Max load: {responseData.cableLoad.to} kg
                                </Typography.Title>
                            </Card>
                            <Card title="Winch">
                                <Typography.Title level={5}>
                                    Max force: {responseData.winchForce / 10} kg
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Diameter: {responseData.winchDiameter} mm
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Length: {responseData.winchLength} mm
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Circles count: {responseData.winchCircleCount}
                                </Typography.Title>
                                <Typography.Title level={5}>
                                    Circle length: {responseData.winchCircleLength} mm
                                </Typography.Title>
                            </Card>
                        </Flex>
                    </Card>
                    <p/>
                    <Card title="Charts">
                        <Tabs defaultActiveKey="1" items={[
                            {
                                key: '1',
                                label: 'Force Cable A',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => tuple[6]}
                                />),
                            },
                            {
                                key: '2',
                                label: 'Force Min',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.min(tuple[6], tuple[7], tuple[8], tuple[9])}
                                />),
                            },
                            {
                                key: '3',
                                label: 'Force Max',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.max(tuple[6], tuple[7], tuple[8], tuple[9])}
                                />),
                            },
                            {
                                key: '4',
                                label: 'Force Max Delta',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.max(tuple[6], tuple[7], tuple[8], tuple[9]) - Math.min(tuple[6], tuple[7], tuple[8], tuple[9])}
                                />),

                            },
                            {
                                key: '5',
                                label: 'Cable Length A',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => tuple[2]}
                                />),
                            },
                            {
                                key: '6',
                                label: 'Cable Length Min',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.min(tuple[2], tuple[3], tuple[4], tuple[5])}
                                />),
                            },
                            {
                                key: '7',
                                label: 'Cable Length Max',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.max(tuple[2], tuple[3], tuple[4], tuple[5])}
                                />),
                            },
                            {
                                key: '8',
                                label: 'Cable Length Max Delta',
                                children: (<HeatMapsTabComponent
                                    data={responseData.data}
                                    extractor={tuple => Math.max(tuple[2], tuple[3], tuple[4], tuple[5]) - Math.min(tuple[2], tuple[3], tuple[4], tuple[5])}
                                />),
                            },
                        ]}/>
                    </Card>
                </div>
            }
        </div>
    );
};

export default ResultForm;