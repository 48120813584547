import React from 'react';
import Plot from "react-plotly.js";

const HeatMapComponent = ({name, data, extractor}) => {
    let xSet = [...new Set(data.map((arr) => arr[0]))];
    let ySet = [...new Set(data.map((arr) => arr[1]))];
    let zSet = [...Array(xSet.length)]
    for (const tuple of data) {
        let xPos = xSet.indexOf(tuple[0]);
        let yPos = ySet.indexOf(tuple[1]);
        if (zSet[xPos] === undefined) zSet[xPos] = [...Array(ySet.length)]
        zSet[xPos][yPos] = extractor(tuple)
    }
    return (
        <Plot
            data={[
                {
                    x: xSet,
                    y: ySet,
                    z: zSet,
                    type: 'heatmap',
                    hoverongaps: false
                }
            ]}
            layout={{width: 500, height: 500, title: 'Z: ' + name + ' mm'}}
        />
    );
}

export default HeatMapComponent;